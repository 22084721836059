table {
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 8rem;
}

th,
td {
  font-size: small;
  width: 50px;
  padding: 0px;
  border: ridge;
  text-align: center;
}

td {
  /* display: flex; */
  padding: 3px 3px;
  width: 100px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

table th {
  /* Apply both top and bottom borders to the <th> */
  border-top: 0px solid;
  border-left: 0px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
}

table tr:nth-child(1) th{
  /* Apply both top and bottom borders to the <th> */
  border-top: 1px solid;
}

table td {
  /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
  border-top: 0px solid;
  border-left: 0px solid;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}

tbody tr:first-child td {
  border-top: 0px solid;
}

table tr th:first-child,
table tr td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid;
}

table tr:nth-child(2) th:first-child {
  border-left: 0px solid;
}

table thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.headingfilter {
  font-size: xx-small;
  color: white;
  border: 3px solid #03A9F4;
  float: right;
  background: #03A9F4;
}

.container {
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  /* overflow-x: scroll; */
  width: auto;
}

.stickyElement {
  position: fixed;
  background-color: #e6f7fffa;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 2%;

}

th {
  background-color: #fff;
  color: #03a9f4;
}

#loader {
  margin-left: 2rem;
  top: 1rem;
  position: relative;
}

#loadMore {
  width: 200px;
  color: #fff;
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: blue;
  transition: .3s;
  position: absolute;
  left: 39%;
  justify-items: center;
  margin-bottom: 21px;
}

#loadMore:hover {
  color: blue;
  background-color: #fff;
  border: 1px solid blue;
  text-decoration: none;
}
.funBtn{
  margin: 12px 0px 0px 18px !important;
  font-size: x-small !important;
  font-weight: bold !important;
}
.headerLbl{
  margin-left: 3px;
  margin-right: 18px
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin-top:25px;
}

.table {
  border-collapse: collapse; /* Collapse table borders */
  width: 100%; /* Make the table take full width */
}
.lblText{
  color: teal;
    font-weight: 600;
    padding: 0px 0px;
}
.dateTot{
  top: 14px;
  height: 44px;
  font-family: sans-serif;
    position: relative;
}

.gridItem{
  border-right-style: ridge;
}

.appiledfont{
  font-family: Roboto,Helvetica,Arial,sans-serif;
  font-size:12px
}
.linkText{
  color: #337ab7;
  text-decoration: revert;
}

.downLoadBtn{
  /* top: 13px; */
    padding-left: 18px;
    height: 43px;
    margin-top: 15px !important;
}


.funBtn_Backtest {
  font-size: 12px !important;
  font-weight: bold !important;
}